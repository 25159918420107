import Link from 'next/link';
import {Col} from 'reactstrap';

const MenuFooter = ({footerCenter, footerRight}) => {
    return (
        <>
            <Col xl='3' lg='3' md='4' sm='6'>
                <div className='footer-links'>
                    <div className='footer-title'
                    >
                        <h3>Information</h3>
                    </div>
                    <div className={`footer-content d-sm-block`}
                    >
                        <ul>
                            {footerCenter !== undefined && footerCenter[0]?.menu_nodes?.map((menu) => {

                                return (
                                    <li key={menu.id}>
                                        <Link href={menu.url} className='font-dark'>{menu.title}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </Col>
            <Col xl='3' lg='3' md='4' sm='6'>
                <div className='footer-links'>
                    <div className='footer-title'
                    >
                        <h3>Customer Service</h3>
                    </div>
                    <div className={`footer-content d-sm-block`}
                    >
                        <ul>
                            {footerRight !== undefined && footerRight[0]?.menu_nodes?.map((menu) => {
                                return (
                                    <li key={menu.id}>
                                        <Link href={menu.url} className='font-dark'>{menu.title}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </Col>
            <Col xl='3' lg='3' md='4' sm='6'>
                <div className='footer-links'>
                    <div className='footer-title'
                    >
                        <h3>Help & Support</h3>
                    </div>
                    <div className={`footer-content d-sm-block`}
                    >
                        <ul>
                            <li>
                                <Link href="/faqs" className='font-dark'>FAQs</Link>
                            </li>
                            <li>
                                <Link href="/feedback" className='font-dark'>Feedback</Link>
                            </li>
                            <li>
                                <Link href="/shipping-policy" className='font-dark'>Shipping</Link>
                            </li>
                            <li>
                                <Link href="/downloads" className='font-dark'>Downloads</Link>
                            </li>
                            <li>
                                <Link href="/video-tutorials/" className='font-dark'>Video Tutorials</Link>
                            </li>
                            <li>
                                <Link href="/return-and-refund" className='font-dark'>Return and Refund</Link>
                            </li>
                            <li>
                                <Link href="/terms-conditions" className='font-dark'>Terms and Conditions</Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </Col>
        </>
    );
};
export default MenuFooter;
